import React, { useState, useRef, useEffect } from "react";
import { usePdf } from "@mikecousins/react-pdf";
import pdf from "../pdf/ETNetwork_Public_Case.pdf";
import SEO from "../components/seo";
import "../pdf/pdf.scss";
import opsioLogo from "../images/opsio-logo.svg";
import { Link } from "gatsby";

const ETNetworkCaseStudy = () => {
  const [page, setPage] = useState(1);
  const canvasRef = useRef(null);

  const { pdfDocument, pdfPage } = usePdf({
    file: pdf,
    page,
    canvasRef,
    scale: 1.5,
  });

  return (
    <>
      <SEO
        title="Läs mer om ETnetwork Success Case"
        description="Läs mer om ETnetwork Success Case"
      />
      <div>
        <Link className="p-4 logoLink" to={`/`}>
          <img className="mt-2" src={opsioLogo} alt="opsioLogo" loading="lazy"/>
        </Link>
        {!pdfDocument && <span>Loading...</span>}

        <h1 className="text-center">Etnetwork</h1>

        {Boolean(pdfDocument && pdfDocument.numPages) && (
          <div className="d-flex justify-content-between px-5 align-items-center h-100">
            <button
              className="pdfBtn"
              disabled={page === 1}
              onClick={() => setPage(page - 1)}
            >
              &lt;
            </button>
            <canvas ref={canvasRef} />
            <button
              className="pdfBtn"
              disabled={page === pdfDocument.numPages}
              onClick={() => setPage(page + 1)}
            >
              &gt;
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default ETNetworkCaseStudy;
